<template>
  <div class="Contact page-view">
    <ContactPage />
  </div>
</template>

<script>
import ContactPage from "@/components/Contact.vue";

export default {
  name: "Contact",
  components: {
    ContactPage
  },
  methods: {
    scroll: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount: function() {
    setTimeout(() => this.scroll(), 100);
  }
};
</script>

<style lang="scss">
.Contact {
  width: 100% !important;
}
</style>
