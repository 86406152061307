<template>
  <section class="contact-page">
    <div class="menu-right-button">
      <Menu />
    </div>

    <div class="contact-header">
      <h1>KONTAKT</h1>
      <a href="/">
        <div class="mini-logo">
          <span class="logo-font">
            Web
            <img class="logo" src="../assets/logo.png" />
            Vision
          </span>
        </div>
      </a>
    </div>
    <div class="main-container" v-scrollanimation>
      <div class="right-line"></div>
      <div class="left-content">
        <h3>Zainteresowaliśmy Cię? <br />odezwij się do nas!</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="280px"
          height="210px"
          fill="currentColor"
          class="bi bi-envelope-fill env-icon"
          viewBox="0 0 16 16"
        >
          <path
            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
          />
        </svg>
      </div>
      <div class="right-content">
        <input
          class="contact-input"
          type="text"
          id="name"
          placeholder="IMIĘ*"
          required
        /><br />
        <input
          class="contact-input"
          type="text"
          id="surname"
          placeholder="NAZWISKO*"
          required
        /><br />
        <input
          class="contact-input"
          type="email"
          id="mail"
          placeholder="E-MAIL*"
          required
        /><br />
        <input
          class="contact-input"
          type="text"
          id="subject"
          placeholder="TEMAT*"
          required
        /><br />
        <textarea
          name="message"
          id="contact-textarea"
          placeholder="WIADOMOŚĆ*"
          required
        >
        </textarea>

        <button
          v-on:click="sendForm()"
          class="submit-buttom"
          type="button"
          name="send"
        >
          <transition leave-active-class="animate__animated animate__bounceOut">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-cursor"
              viewBox="0 0 16 16"
              v-if="show"
            >
              <path
                d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"
              />
            </svg>
          </transition>
          <transition
            enter-active-class="animate__animated animate__bounceIn animate__slower	"
          >
            <b-icon v-if="show2" icon="x" scale="2.5" variant="danger"></b-icon>
          </transition>
          <transition
            enter-active-class="animate__animated animate__bounceIn animate__slower	"
          >
            <b-icon
              v-if="show3"
              icon="check2"
              scale="2"
              variant="success"
            ></b-icon>
          </transition>
        </button>
      </div>
    </div>
    <footerm />
  </section>
</template>

<script>
import footerm from "@/components/footer.vue";
import Menu from "@/components/MenuBurger.vue";
import axios from "axios";
export default {
  name: "Contact",
  components: {
    Menu,
    footerm
  },
  data() {
    return {
      show: true,
      show2: false,
      show3: false
    };
  },
  methods: {
    working() {
      setTimeout(() => (this.show = false), 50);
      setTimeout(() => (this.show3 = true), 600);
      document.getElementById("name").value = "";
      document.getElementById("surname").value = "";
      document.getElementById("mail").value = "";
      document.getElementById("subject").value = "";
      document.getElementById("contact-textarea").value = "";
    },
    notWorking() {
      setTimeout(() => (this.show = false), 50);
      setTimeout(() => (this.show2 = true), 600);
    },
    sendForm() {
      var name = document.getElementById("name").value;
      var surname = document.getElementById("surname").value;
      var mail = document.getElementById("mail").value;
      var subject = document.getElementById("subject").value;
      var msg = document.getElementById("contact-textarea").value;

      var params = {
        name: name,
        surname: surname,
        mail: mail,
        subject: subject,
        msg: msg
      };
      var _this = this;
      axios
        .post("https://webvision.art/mail/mail.php", params)
        .then(function(response) {
          let data = response.data;
          if (data == "DoReMiFaSoLaSiDoWyslano") {
            _this.working();
          } else {
            console.log(data);
            _this.notWorking();
          }
        });
    }
  }
};
</script>

}
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/Contact/_Contact.scss";
@import "@/assets/styles/Contact/responsive/xlarge_D.scss";
@import "@/assets/styles/Contact/responsive/large_D.scss";
@import "@/assets/styles/Contact/responsive/medium_D.scss";
@import "@/assets/styles/Contact/responsive/small_D.scss";
@import "@/assets/styles/Contact/responsive/xsmall_D.scss";
</style>
